import {
  isSubscriptionIncomplete,
  isSubscriptionIncompleteExpired,
} from "@/react/helpers/AccountSubscriptionHelpers";
import { isPromoCodeEnabled } from "@/react/helpers/communityHelpers";
import {
  isAnnual,
  isPlanBasic,
  isPlanBusiness,
  isPlanEnterprise,
  isPlanPlus,
  isPlanProfessional,
  isUpgrading,
} from "@/react/helpers/planHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMarketingHubStatusEnabled } from "./useIsMarketingHubStatus";

export const usePlanStatus = () => {
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();
  const {
    is_on_trial: isOnTrial,
    default_currency: defaultCurrency = null,
    is_expired_trial: isExpiredTrial = false,
    can_choose_currency: canChooseCurrency = false,
    current_plan_tier: currentPlanTier = "",
    current_plan_interval: currentPlanInterval,
    has_addons: hasAddons = false,
    has_active_offer: hasActiveOffer = false,
    current_plan: currentPlan,
  } = currentCommunity ?? {};

  const isMarketingHubStatusEnabled = useIsMarketingHubStatusEnabled();
  const isCommunityOnTrial = Boolean(isOnTrial);
  const isCommunityOnPlus = isPlanPlus(currentPlanTier);
  const isCommunityOnEnterprise = isPlanEnterprise(currentPlanTier);
  const isCommunityOnProfessional = isPlanProfessional(currentPlanTier);
  const isCommunityOnBasic = isPlanBasic(currentPlanTier);
  const isCommunityOnBusiness = isPlanBusiness(currentPlanTier);
  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);

  const isCommunitySubscriptionIncompleteExpired =
    isSubscriptionIncompleteExpired(currentCommunity?.subscription?.status);

  const isCommunitySubscriptionIncomplete = isSubscriptionIncomplete(
    currentCommunity?.subscription?.status,
  );

  const isCommunitySubscriptionFullyCancelled = Boolean(
    currentCommunity?.subscription?.is_cancelled &&
      !currentCommunity?.subscription?.on_grace_period,
  );

  const isCommunitySubscriptionOnGracePeriod =
    !!currentCommunity?.subscription?.on_grace_period;

  // past_due or incomplete
  const hasCommunityIncompletePayment =
    !!currentCommunity?.subscription?.has_incomplete_payment;

  const changePlanModalParams = (minimumRequiredTier: string) => {
    // Note: for later, we should allow upgrades between the same tier, as some legacy plans
    // doesn't have access to new features already available in the new version of tiers
    const isUpgradingPlan = isUpgrading(currentPlanTier, minimumRequiredTier);

    if (isCommunityOnTrial) {
      if (currentPlan?.public) {
        return isUpgradingPlan
          ? { planTier: minimumRequiredTier }
          : { planTier: currentPlanTier };
      }

      return isUpgradingPlan
        ? { planTier: minimumRequiredTier }
        : { planId: currentPlan?.id };
    }

    return { planTier: minimumRequiredTier };
  };

  const isCommunityOnPaidPlan = Boolean(
    !isCommunityOnTrial &&
      !isExpiredTrial &&
      currentCommunity?.subscription &&
      !isCommunitySubscriptionIncompleteExpired &&
      !isCommunitySubscriptionFullyCancelled,
  );

  const shouldCollectTaxId = (plan: any) =>
    plan.currency === "brl" && isCommunityOnTrial && !isExpiredTrial;

  const shouldBlockUpgrade = (
    newPlan: any,
    isAdminApiActive: boolean,
    isHeadlessApiActive: boolean,
  ) => {
    if (currentCommunitySettings?.deterministic_upgrade_service_enabled) {
      return false;
    }

    return (
      (isAdminApiActive || isHeadlessApiActive) &&
      newPlan &&
      currentPlanInterval !== newPlan.interval
    );
  };

  const shouldShowProration = (newPlan: any) => {
    if (isMarketingHubStatusEnabled) {
      return false;
    }

    if (!newPlan) {
      return false;
    }

    if (isCommunityOnTrial || isExpiredTrial) {
      return false;
    }

    if (currentPlanInterval != newPlan.interval) {
      return true;
    }

    if (isAnnual(currentPlanInterval) && isAnnual(newPlan.interval)) {
      return true;
    }

    return hasAddons;
  };

  return {
    isCommunityOnTrial,
    isCommunityOnEnterprise,
    isCommunityOnProfessional,
    isCommunityOnBasic,
    isCommunityOnPlus,
    isCommunityOnBusiness,
    isCommunityOnPaidPlan,
    currentPlanTier,
    hasActiveOffer,
    shouldCollectTaxId,
    defaultCurrency,
    canChooseCurrency,
    isExpiredTrial,
    shouldShowProration,
    isCommunitySubscriptionIncompleteExpired,
    isCommunitySubscriptionIncomplete,
    isCommunitySubscriptionFullyCancelled,
    isCommunitySubscriptionOnGracePeriod,
    hasCommunityIncompletePayment,
    isPromoCodeEnabledForCommunity,
    changePlanModalParams,
    shouldBlockUpgrade,
  };
};
