import { usePunditUserContext } from "@circle-react/contexts";

// messaging_enabled here refers to output of model level method `messaging_enabled?` and not the column in the database.
// It considers the community and community member level settings to determine if messaging is enabled for the user.
export const useIsMessagingEnabled = () => {
  const { currentCommunityMember } = usePunditUserContext();
  return Boolean(currentCommunityMember?.messaging_enabled || false);
};

// Add audience filter check here in future
export const useIsCommunityBotMessagingEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  return Boolean(currentCommunitySettings?.community_bot_enabled);
};

//remove_granular_dm_settings_feature_flag: remove this method
export const useIsGranularDmSettingsEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  return Boolean(currentCommunitySettings?.granular_dm_settings_enabled);
};

export const useShouldShowNewMessagingButton = () => {
  const isMessagingEnabled = useIsMessagingEnabled();
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();

  return isMessagingEnabled || isBotMessagingEnabled;
};

export const useShouldShowMessagingRoutes = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();
  const isGranularDmSettingsEnabled = useIsGranularDmSettingsEnabled();
  const isMessagingEnabled = useIsMessagingEnabled();

  //remove_granular_dm_settings_feature_flag: remove if block
  if (!isGranularDmSettingsEnabled) {
    const shouldEnableDMs =
      !isMessagingEnabled &&
      !!currentCommunityMember?.policies?.can_access_direct_messages;

    const shouldShowMessagesTab =
      shouldEnableDMs ||
      (currentCommunityMember?.messaging_enabled_by_admin &&
        isMessagingEnabled);

    return {
      shouldShowMessagingRoutes: Boolean(
        isBotMessagingEnabled || shouldShowMessagesTab,
      ),
      showMessagesTab: shouldShowMessagesTab,
    };
  }

  //If the messaging enabled for a member based on settings or
  // If the member can access direct messages
  const hasMessagesTab = Boolean(
    isMessagingEnabled ||
      !!currentCommunityMember?.policies?.can_access_direct_messages,
  );

  //shouldShowMessagingRoutes: display messaging specific routes on the app.
  //showMessagesTab: display messaging tab like inbox, unread on the app.
  return {
    shouldShowMessagingRoutes: Boolean(isBotMessagingEnabled || hasMessagesTab),
    showMessagesTab: hasMessagesTab,
  };
};
